import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Curs Online per la Guàrdia Urbana de Cornellà 2024</Title>
                <Text>
                    Arriba un curs específic per preparar l'accés a la Guàrdia Urbana de Cornellà
                    2024
                    <br />
                    <br />
                    Un curs, complementari al curs ONLINE de policia local que tenim a la web.
                    <br />
                    <br />
                    Els cursos específics de iOpos són molts complerts, ja que ens basem en els
                    patrons d'anys anteriors.
                    <br />
                    <br />
                    Són cursos fàcils d'estudiar, on tu decideixes com i quan avançar.
                    <br />
                    <br />
                    Al curs trobaràs tot el contingut necessari per posicionar-te entre els millors.
                    <br />
                    <br />
                    Un curs viu i en constant evolució.
                    <br />
                    <br />
                    Dins del curs trobaràs:
                    <br />
                    <br />
                    - Cultura general.
                    <br />
                    <br />
                    - Consistori
                    <br />
                    <br />
                    - Notícies d'actualitat.
                    <br />
                    <br />
                    - Premis
                    <br />
                    <br />
                    - El Municipi.
                    <br />
                    <br />
                    -Dades rellevants
                    <br />
                    <br />
                    - 3 exàmens oficials
                    <br />
                    <br />
                    I Simulacres on et posarem a prova!!
                    <br />
                    <br />
                    Per només 29,99 euros.
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen
                    <br />
                    <br />
                    Entra ara i avança al teu ritme!
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
